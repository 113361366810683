.phoneInputVerificationHolder {
  display: flex;
  flex-direction: row;
}

.phoneInputVerificationHolder > input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.phoneVerificationButton {
  position: relative;
  width: 86px;
  font-size: 17px;
  height: 45px !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 12px;
}

.phoneVerificationButton.orderButton::after {
  right: 13px;
  width: 10px;
  height: 10px;
}

.phoneVerifiedIndication {
  position: relative;
  height: 45px;
  width: 55px;
  font-size: 18px;
  border: 1px solid #00b4e4;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #00b4e4;
  margin-top: 5px;
  margin-left: -5px;
  padding-left: 18px;
  line-height: 40px;
}

.checkMark {
  display: inline-block;
  transform: rotate(45deg);
  height: 18px;
  width: 10px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

#phoneVerificationModal > .modal-content {
  padding: 0;
}

#phoneVerificationModal > .modal-content > .modal-body {
  padding: 30px;
}

#phoneVerificationModal > .modal-content > .modal-header {
  background-color: #00b4e4;
  color: white;
  border-radius: 0;
}

#phoneVerificationModal > .modal-content > .modal-header > .modal-title {
  margin: 0 auto;
}

#phoneVerificationModal > .modal-content > .modal-footer {
  height: 50px;
  padding: 0;
}

#phoneVerificationModal > .modal-content > .modal-footer > button {
  width: 50%;
  height: 100%;
  border: 0px solid white;
  border-radius: 0;
  margin: 0;
  height: 100%;
}

#phoneVerificationModal
  > .modal-content
  > .modal-footer
  > #closeVerificationCodeModal {
  background-color: white;
  color: gray;
}

#phoneVerificationModal
  > .modal-content
  > .modal-footer
  > #confirmVerificationCodeModal {
  background-color: #00b4e4;
  color: white;
}

.resendOtpHolder {
  width: max-content;
  margin: 20px 0 0 auto;
  font-size: 13px;
}

.resendOtpButton {
  color: #00b4e4;
  text-decoration: underline;
  cursor: pointer;
}

.custom-styles {
  width: max-content;
  margin: auto;
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
}
